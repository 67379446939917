<template>
  <div>
    <el-form ref="ipformref" :model="ipform" label-width="130px">
      <el-form-item :label="$t('cs.qdipdzjx')" required>
        <el-switch
          v-model="ipform.ip_isopend"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </el-form-item>

      <el-form-item label="Api Key">
        <el-input v-model="ipform.apikey"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ipform: {
        ip_isopend: '0',
        apikey: ''
      }
    }
  },
  created() {
    this.getip()
  },
  methods: {
    async getip() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/config/ipconfig')
      if (data) {
        if (data.code === 200) {
          this.ipform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交
    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.post(
        '/admin/config/ipconfig',
        this.ipform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 重置
    reset() {
      this.getip()
    }
  }
}
</script>
